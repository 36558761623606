@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Bungee&display=swap");

* {
  font-family: "Bungee", sans-serif;
}

.hover-inside-border {
  transition: all 200ms ease-in-out;
}

.hover-inside-border:hover {
  border: inset 4px orange;
}

.img-fly-up-down {
  animation: fly 3s linear infinite;
}

.hero {
  position: relative;
  top: 80;
  width: 100%;
  min-height: 110vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #0b1f47;
}

.hero-container {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  bottom: 20px;
}

.hero-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.hero-title > img {
  z-index: 2;
  width: 20%;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.hero-title > img:hover {
  animation: fly 2s linear infinite;
}

.hero-title > p span {
  color: #fff;
  font-size: 220px;
  font-weight: 400;
  text-align: center;
  line-height: 78%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  display: inline-block;
  text-shadow: 0.0125786164em 0.0377358491em 0 #000,
    -0.0125786164em 0.0377358491em 0 #000, -0.0125786164em 0.0125786164em 0 #000,
    -0.0125786164em -0.0125786164em 0 #000,
    0.0125786164em -0.0125786164em 0 #000;
}

p.orange span {
  color: #f56221 !important;
}

.hero-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.hero-btn img {
  width: 50px;
  border-radius: 100px;
  height: 50px;
}

.hero-btn:hover {
  transform: scale(1.1);
}

.hero-btn-1 {
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 16px 36px;
  border: 4px solid;
  border-color: transparent;
  font-size: 16px;
  background: linear-gradient(90deg, #ff4800 0%, #ff7b00 100%);
  border-radius: 100px;
  color: #1f387e;
  box-shadow: 0 0 0 2px #ffffff;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  height: 50px;
  font-family: unset;
}

.hero-btn-1 svg {
  position: absolute;
  width: 24px;
  fill: #1f387e;
  z-index: 9;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.hero-btn-1 .arr-1 {
  right: 16px;
}

.hero-btn-1 .arr-2 {
  left: -25%;
}

.hero-btn-1 .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: #c5e5e4;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.hero-btn-1 .text {
  position: relative;
  z-index: 1;
  transform: translateX(-12px);
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.hero-btn-1:hover {
  transform: scale(1.1);
}

.hero-btn-2 {
  position: relative;
  width: 140px;
  height: 50px;
  background-color: #000;
  display: flex;
  align-items: center;
  color: white;
  flex-direction: column;
  justify-content: center;
  border: none;
  padding: 12px;
  gap: 12px;
  border-radius: 8px;
  cursor: pointer;
  border-radius: 100px;
  /* background: linear-gradient(-45deg, #ff4800 0%, #ff7b00 100%); */
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border: 2px solid #ff4800;
}

.hero-btn-2:hover {
  transform: scale(1.1);
}

.swiper-button-next:after,
.swiper-button-prev:after {
  content: "" !important;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 40px;
  }
}

/* ===============================Section 2=========================================== */
.section-2 {
  background-color: rgb(255, 0, 0);
  border: 2px solid;
}

.section-2 .wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.section-2 .wrapper .left .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  /* align-items: center; */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
}

.section-2 .wrapper .left p span {
  color: #fff;
  font-size: 80px;
  font-weight: 400;
  text-align: center;
  line-height: 78%;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  display: inline-block;
  text-shadow: 0.0125786164em 0.0377358491em 0 #000,
    -0.0125786164em 0.0377358491em 0 #000, -0.0125786164em 0.0125786164em 0 #000,
    -0.0125786164em -0.0125786164em 0 #000,
    0.0125786164em -0.0125786164em 0 #000;
}

.section-2 .wrapper .left img {
  max-width: 40%;
}

.section-2 .wrapper .right {
  display: grid;
}

.section-2 .wrapper .right .link h3 {
  font-size: 50px;
}

.section-2 .wrapper .right .link:hover {
  text-decoration: underline;
}

/* ===============================Section 3=========================================== */
.section-3 {
  background-color: #0b1f47;
  color: #fff;
}

.section-3 .wrapper {
  max-width: 800px;
}

.section-3 .wrapper .title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-3 .wrapper .title-wrapper img {
  position: absolute;
  z-index: 2;
  width: 250px;
}

.section-3 .wrapper .title p,
.section-3 .wrapper .sub-title p {
  z-index: 35;
  position: relative;
  color: #fff;
  font-size: 80px;
  font-weight: 400;
  text-align: center;
  line-height: 78%;
  letter-spacing: -0.01em;
  display: inline-block;
  text-shadow: 0.0125786164em 0.0377358491em 0 #000,
    -0.0125786164em 0.0377358491em 0 #000, -0.0125786164em 0.0125786164em 0 #000,
    -0.0125786164em -0.0125786164em 0 #000,
    0.0125786164em -0.0125786164em 0 #000;
}

.section-3 .wrapper .sub-title p {
  font-size: 60px;
}

.section-3 .wrapper .content .content-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f56221;
  padding: 0 20px;
  border-radius: 20px;
  min-height: 96px;
  transform: rotate(-10deg);
}

.section-3 .wrapper .content .content-item p {
  transform: rotate(10deg) !important;
}

.section-3 .wrapper .content p {
  position: relative;
  z-index: 10;
  flex: auto;
  max-width: 600px;
}

.section-3 .wrapper .content p:nth-child(2) {
  left: 45px;
}

/* .section-3 .wrapper .content .content-item img {
  position: absolute;
  z-index: 2;
  width: 180px;
} */

.section-3 .fly-img {
  max-width: 200px;
  max-height: 200px;
  z-index: 1;
  animation: fly 4s linear infinite;
}

@keyframes fly {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

/* =================================Section-4========================================= */
.section-4 {
  width: 100%;
  height: 100%;
  background-color: #0b1f47;
  color: #fff;
  min-height: 100vh;
}

.section-4 .main-container {
  max-width: 1280px;
}

.section-4 .address-box {
  background: #121212;
  width: fit-content;
  padding: 40px 20px;
  border-radius: 20px;
}

.section-4 .address-box .sub-title {
  position: relative;
  color: #f56221;
  font-size: 22px;
  text-align: center;
  line-height: 78%;
  letter-spacing: -0.01em;
  display: inline-block;
  text-shadow: 0.0125786164em 0.0377358491em 0 #000,
    -0.0125786164em 0.0377358491em 0 #000, -0.0125786164em 0.0125786164em 0 #000,
    -0.0125786164em -0.0125786164em 0 #000,
    0.0125786164em -0.0125786164em 0 #000;
  width: 100%;
}

.section-4 .address-box .address p {
  font-family: sans-serif !important;
  font-weight: 500;
  color: #fff;
}

.section-4 .boxes {
  margin-top: 200px;
  position: relative;
}

.section-4 .boxes .box {
  background: #151a1f;
  box-shadow: inset 0 -1px 6px 0 #fff3;
  width: 100%;
  height: 100%;
  padding: 20px;
  min-height: 140px;
  z-index: 10;
  position: relative;
  border-radius: 20px;
}

.section-4 .boxes .box .sub-title {
  position: relative;
  width: 100%;
  color: #f56221;
  font-size: 22px;
  text-align: center;
  line-height: 78%;
  letter-spacing: -0.01em;
  display: inline-block;
  text-shadow: 0.0125786164em 0.0377358491em 0 #000,
    -0.0125786164em 0.0377358491em 0 #000, -0.0125786164em 0.0125786164em 0 #000,
    -0.0125786164em -0.0125786164em 0 #000,
    0.0125786164em -0.0125786164em 0 #000;
}

.section-4 .boxes {
  margin-top: 120px;
  position: relative;
}

.section-4 .bottom-box {
  margin-top: 0;
  position: relative;
  bottom: 0px;
}

.section-4 .boxes .img-box {
  position: relative;
  z-index: 2;
  bottom: 60px;
}

.section-4 .boxes .img-box {
  background: transparent;
}

.section-4 .bottom-box .box {
  max-width: 375px;
}

.section-4 .boxes .img-box img {
  width: 250px;
}

.section-4 .boxes .img-box img:hover {
  animation: fly 2s linear infinite;
}

/* ========================================.section-5============================================ */

.section-5 {
  background-color: #000;
  color: #fff;
}

.section-5 .main-container {
  max-width: 1280px;
}

.section-5 .main-container .title p {
  position: relative;
  color: #fff;
  font-size: 60px;
  line-height: 78%;
  letter-spacing: -0.01em;
  display: inline-block;
  text-shadow: 0.0125786164em 0.0377358491em 0 #000,
    -0.0125786164em 0.0377358491em 0 #000, -0.0125786164em 0.0125786164em 0 #000,
    -0.0125786164em -0.0125786164em 0 #000,
    0.0125786164em -0.0125786164em 0 #000;
  line-height: 1.2;
}

.section-5 .book-card {
  min-height: 648px;
  background: #151a1f;
  box-shadow: inset 0 -1px 6px 0 #fff3;
  border-radius: 10px;
}

.section-5 .book-card .sub-title p {
  position: relative;
  color: #fff;
  font-size: 50px;
  line-height: 78%;
  letter-spacing: -0.01em;
  display: inline-block;
  text-shadow: 0.0125786164em 0.0377358491em 0 #000,
    -0.0125786164em 0.0377358491em 0 #000, -0.0125786164em 0.0125786164em 0 #000,
    -0.0125786164em -0.0125786164em 0 #000,
    0.0125786164em -0.0125786164em 0 #000;
  line-height: 1.2;
}

.section-5 .book-card .sub-title-2 {
  color: #fff;
  font-size: 30px;
  line-height: 78%;
  letter-spacing: -0.01em;
  display: inline-block;
  text-shadow: 0.0125786164em 0.0377358491em 0 #000,
    -0.0125786164em 0.0377358491em 0 #000, -0.0125786164em 0.0125786164em 0 #000,
    -0.0125786164em -0.0125786164em 0 #000,
    0.0125786164em -0.0125786164em 0 #000;
  line-height: 1.2;
}

.section-5 .book-card .paragraphs p,
.section-5 .book-card .paragraphs span,
.section-5 .book-card .book-card-link {
  font-family: sans-serif;
  color: #fff;
  font-size: 16px;
}

.section-5 .book-card .book-card-link {
  font-style: italic;
  text-decoration: underline;
  transition: all 0.3s;
}

.section-5 .book-card .book-card-link:hover {
  color: rgb(53, 53, 240);
}

.swiper-3d .swiper-slide-shadow,
.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-top,
.swiper-3d .swiper-slide-shadow-bottom,
.swiper-3d .swiper-slide-shadow,
.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-top,
.swiper-3d .swiper-slide-shadow-bottom {
  position: static !important;
}

.disclamer {
  font-size: 10px;
  font-family: sans-serif;
}

/* ==============================Section-6======================================== */
.section-6 {
  background-color: #0b1f47;
  color: #fff;
}

.section-6 .wrapper {
  max-width: 1280px;
}

.section-6 .wrapper .main-box {
  background: #151a1f;
  box-shadow: inset 0 -1px 6px 0 #fff3;
  color: #fff;
}

.section-6 .wrapper .title p {
  position: relative;
  color: #fff;
  font-size: 200px;
  line-height: 50%;
  letter-spacing: -0.01em;
  display: inline-block;
  text-shadow: 0.0125786164em 0.0377358491em 0 #000,
    -0.0125786164em 0.0377358491em 0 #000, -0.0125786164em 0.0125786164em 0 #000,
    -0.0125786164em -0.0125786164em 0 #000,
    0.0125786164em -0.0125786164em 0 #000;
  line-height: 1.2;
}

.section-6 .wrapper .main-box .right .sub-heading {
  font-size: 28px;
}

.section-6 .wrapper .main-box .right .paragraph {
  font-size: 16px;
  line-height: 1.8;
}

.section-6 .wrapper .main-box .right .points li,
.section-6 .wrapper .main-box .right .points li a {
  font-family: sans-serif !important;
  list-style-type: square;
}

.section-6 .wrapper .main-box .right .disclamer > * {
  font-size: 12px;
  font-family: sans-serif !important;
}

/* ===============================Responsive=========================================== */

@media only screen and (max-width: 1100px) {
  .hero-title > p span {
    font-size: 140px;
    text-align: center;
  }

  .hero-container {
    bottom: 0;
  }
}

@media only screen and (max-width: 950px) {
  .section-2 .wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .hero-title > p span {
    font-size: 110px;
    text-align: center;
  }

  .hero-container {
    bottom: 0;
  }

  .section-3 .wrapper .content p:nth-child(2) {
    left: 0;
  }

  .section-4 .boxes {
    margin-top: 50px;
  }

  .section-4 .boxes .img-box {
    display: none;
  }

  .section-4 .boxes .box:nth-child(2) {
    transform: translateY(0);
  }

  .section-4 .boxes {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 600px) {
  .hero-title > p span {
    font-size: 65px;
    text-align: center;
  }

  .hero-title > img {
    position: static;
    z-index: 2;
    width: 40%;
  }

  .hero-btn-1,
  .hero-btn-2 {
    font-size: 14px;
    width: 150px;
  }

  .hero-btn-2::before {
    width: 158px;
  }

  .section-3 .wrapper .sub-title p {
    line-height: 1;
  }

  .section-2 .wrapper .left p span,
  .section-3 .wrapper .title p,
  .section-3 .wrapper .sub-title p {
    font-size: 45px;
  }

  .section-4 .address-box,
  .section-4 .boxes .box {
    width: 100%;
  }

  .section-4 .address-box .address {
    flex-wrap: wrap;
    justify-content: center;
  }

  .section-4 .address-box .address p {
    word-break: break-all;
    text-align: center;
  }

  .section-4 .boxes .box .sub-title,
  .section-4 .address-box .sub-title {
    line-height: 1.2;
  }

  .section-5 .main-container .title p {
    font-size: 45px;
  }

  .section-6 .wrapper .title p {
    font-size: 80px;
  }
}

@media only screen and (max-width: 400px) {
  .hero-title > p span {
    font-size: 60px;
    text-align: center;
  }

  .section-2 .wrapper .right .link h3 {
    font-size: 40px;
  }

  .section-6 .wrapper .main-box .right .sub-heading {
    font-size: 24px;
  }
}
